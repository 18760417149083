<template>
  <div>
    <search-sms-channel @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('sms_channels/new')"
        >
          新增
        </a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="effective" slot-scope="effective, record">
        <a-space>
          <a-switch
            :checked="effective"
            :loading="editingEffectiveId === record.id"
            @change="(checked) => changeEffective(checked, record)"
          />
        </a-space>
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>
    </a-table>

    <!-- 分页 -->
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增短信接口 -->
    <new-sms-channel
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑短信接口 -->
    <edit-sms-channel
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      @completed="fetchData"
      :id="editingId"
    />
  </div>
</template>

<script>
import SearchSmsChannel from '@/views/sms_channels/Search'
import { hasPermission } from '@/utils/permission'
import Pagination from '@/components/Pagination'
import { findSmsChannels, updateSmsChannelEffective } from '@/api/sms_channel'
export default {
  name: 'SmsChannelList',
  components: {
    SearchSmsChannel,
    Pagination,
    NewSmsChannel: () => import('@/views/sms_channels/New'),
    EditSmsChannel: () => import('@/views/sms_channels/Edit')
  },
  data() {
    return {
      data: [],
      loading: false,
      isShowNewModal: false,
      isShowEditModal: false,
      editingId: 0,
      editingEffectiveId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '短信接口名称',
          width: 200,
          dataIndex: 'name'
        },
        {
          title: '运营商种类',
          width: 100,
          dataIndex: 'carrier_type'
        },
        {
          title: '所属运营商账户个数',
          width: 100,
          dataIndex: 'carriers_count'
        },
        {
          title: '有效性',
          width: 100,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          width: 100,
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    isHasEditPermission() {
      return hasPermission('sms_channels/edit')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked
      this.$confirm({
        title: effective ? '确定生效吗?' : '确定失效吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateSmsChannelEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    fetchData() {
      this.loading = true
      findSmsChannels(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
